import styled, { css } from 'styled-components';

interface ContainerProps {
  error?: boolean;
}
interface ButtonActionProps {
  saveDisable?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .containerButton {
    position: absolute;
    right: 10px;
    bottom: 0px;
  }
  .editButton {
    box-shadow: -3px 4px 9px #00000040;
    border: none;
    margin: 4px;
    padding: 0px;
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 50%;
    position: relative;

    transition: 0.5s;
    &:hover {
      transform: scale(1.1);
    }
  }

  label {
    font-size: 16px;
    color: #999;
    text-decoration: underline;
    text-decoration-color: #dff;
  }

  textarea {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #e6e6f0;
    padding: 22px;
    font-size: 15px;
    background-color: #fafafc;

    &::placeholder {
      color: #999;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
    &:disabled {
      background: #cccccc54;
      cursor: not-allowed;
    }
    ${(props) =>
      props.error &&
      css`
        border: 1px solid #ed2945;
      `}
  }

  .text-hint {
    margin-top: 2px;
    color: #838383;
    font-size: 10px;
  }
`;
export const ActionButton = styled.button<ButtonActionProps>`
  background: ${(props) => (props.saveDisable ? '#83838373' : '#fff')};
`;
