import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* padding-top: 6%; */
  /* background-color: #286738; */
  position: fixed;
`;
export const Content = styled.div`
  max-width: 70%;
  color: #000;
  overflow: auto;

  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 900;
    white-space: nowrap;
  }
  @media only screen and (max-width: 425px) {
    & {
      max-width: 100%;
      padding: 0 12px;
    }
  }
`;
