/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useCallback, useState } from 'react';

import { TypesPrices } from 'interfaces/prices';
import FullLoader from 'components/FullLoader';
import { useMenu } from 'context';

import api from 'services/api';
import { PriceCourts } from 'interfaces/priceCourts';
import Packages from './Packages';
import Monthly from './Monthly';
import { Container, OptionsContainer, Option, DisplayGrid } from './styles';
import Reservation from './Reservations';

interface RadioTypes {
  id: string;
  label: string;
  value: string;
}
interface RadioTypesCourts {
  id: string;
  courtname: string;
  value: string;
}

const Prices: React.FC = () => {
  const radioTypes: RadioTypes[] = [
    {
      id: 'Avulsos',
      label: 'Reservas',
      value: '3',
    },
  ];

  const [loading, setLoading] = useState(false);
  const [priceItem, setPriceItem] = useState('');
  const [typesPrices, setTypesPrices] = useState<TypesPrices[]>([]);
  const [court, setCourt] = useState('');
  const [courts, setCourts] = useState<RadioTypesCourts[]>([]);
  const [courtSelected, setCourtSelected] = useState<RadioTypesCourts>(
    {} as RadioTypesCourts
  );

  const handleSelectCourt = useCallback((item: RadioTypesCourts) => {
    setCourtSelected(item);
    setCourt(item.value);
  }, []);
  const { setPageTitle } = useMenu();
  useEffect(() => {
    setPageTitle('Preços');
    api
      .get(`/courts/findAll?id_place=${process.env.REACT_APP_ID_PLACE}`)
      .then((response) => {
        setCourts(response.data);
      });
  }, [setPageTitle]);

  return (
    <Container>
      <DisplayGrid>
        <OptionsContainer>
          {radioTypes.map((item) => (
            <Option
              key={item.id}
              selected={priceItem === item.value}
              onClick={() => setPriceItem(item.value)}
            >
              <button type="button" />
              <p>{item.label}</p>
            </Option>
          ))}
        </OptionsContainer>
        {priceItem === '3' ? (
          <OptionsContainer>
            <h2>Quadras</h2>
            {courts.map((item) => (
              <Option
                key={item.id}
                selected={courtSelected === item}
                onClick={() => setCourtSelected(item)}
              >
                <button type="button" />
                <p>{item.courtname}</p>
              </Option>
            ))}
          </OptionsContainer>
        ) : (
          ''
        )}
      </DisplayGrid>
      {priceItem === '1' ? (
        <Packages />
      ) : (
        <>
          {priceItem === '2' ? (
            <Monthly />
          ) : priceItem === '3' ? (
            <Reservation courtSelected={courtSelected} />
          ) : (
            ''
          )}
        </>
      )}
      <FullLoader show={loading} />
    </Container>
  );
};

export default Prices;
