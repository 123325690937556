/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useCallback, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { MdAdd, MdEdit, MdDeleteForever } from 'react-icons/md';

import Table from 'components/Table';
import FullLoader from 'components/FullLoader';
import Pagination from 'components/Pagination';
import { modalStyle } from 'components/ModalStyle';

import { MonthlyProps } from 'interfaces/monthly';
import { useUsers } from 'hooks/users';
import api from 'services/api';

import { toast } from 'react-toastify';
import AddMonthlyModal from './AddMonthlyModal';
import EditMonthlyModal from './EditMonthlyModal';
import { Container } from './styles';

const Packages: React.FC = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [monthly, setMonthly] = useState<MonthlyProps[]>([]);
  const [selectedMonthly, setSelectedMonthly] = useState<MonthlyProps>(
    {} as MonthlyProps
  );
  const dispatch = useDispatch();

  const { count = 0, users = [] } = useUsers();

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(Number(e.target.value));
    setPage(1);
  };

  const handleNextPage = React.useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const handlePreviousPage = React.useCallback(() => {
    setPage((prev) => prev - 1);
  }, []);

  useEffect(() => {
    api.get(`/payers/findAllMonthly`).then((response) => {
      setMonthly(response.data);
      setLoading(false);
    });
  }, [page, itemsPerPage, dispatch]);

  const handleOpenEditModal = useCallback((item: MonthlyProps) => {
    setSelectedMonthly(item);
    setEditModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    api.get(`/payers/findAllMonthly`).then((response) => {
      setMonthly(response.data);
      setAddModalOpen(false);
      setEditModalOpen(false);
    });
  }, []);

  const handleDeletePackage = useCallback((id: string) => {
    const result = window.confirm('Deseja realmente apagar este horário?');
    if (result) {
      api.delete(`/payers/deleteMonthly?id=${id}`).then((response) => {
        toast.success('Horário apagado com sucesso!');
        api.get(`/payers/findAllMonthly`).then((response2) => {
          setMonthly(response2.data);
        });
      });
    }
  }, []);

  return (
    <Container>
      <FullLoader show={loading} />
      <button
        onClick={() => setAddModalOpen(true)}
        className="addPackageButton"
        type="button"
      >
        <MdAdd size={20} color="#fff" />
        <span>Adicionar horário mensal</span>
      </button>
      <div className="table-container">
        <Table>
          <thead>
            <tr>
              <th align="left">Hora</th>
              <th align="left">Preço</th>
              <th align="left">Editar</th>
              <th align="left">Excluir</th>
            </tr>
          </thead>
          <tbody>
            {monthly?.map((item, index) => (
              <tr key={index}>
                <td>
                  <p>{item?.hour}</p>
                </td>
                <td>{`R$ ${String(item?.price).replace('.', ',')}`}</td>
                <td>
                  <button
                    type="button"
                    onClick={() => handleOpenEditModal(item)}
                  >
                    <MdEdit color="#286738" size={20} />
                  </button>
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => handleDeletePackage(item.id)}
                  >
                    <MdDeleteForever color="#cc0000" size={20} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="pagination-container">
          <div className="pagination-options">
            <p>Itens por página</p>
            <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="pagination">
            <Pagination
              offset={(page - 1) * itemsPerPage}
              currentCount={users?.length}
              total={count}
              handlePreviousPage={handlePreviousPage}
              handleNextPage={handleNextPage}
            />
          </div>
        </div>
      </div>
      <Modal isOpen={addModalOpen} style={modalStyle}>
        <AddMonthlyModal closeModal={() => handleCloseModal()} />
      </Modal>
      <Modal isOpen={editModalOpen} style={modalStyle}>
        <EditMonthlyModal
          initialData={selectedMonthly}
          closeModal={() => handleCloseModal()}
        />
      </Modal>
    </Container>
  );
};

export default Packages;
