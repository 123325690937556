/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useEffect,
  useCallback,
  useState,
  useLayoutEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { MdDeleteForever, MdEdit, MdRemoveRedEye } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import Modal from 'react-modal';

import { Table } from '@pdasolucoes/web';
import FullLoader from 'components/FullLoader';

import { useMenu } from 'context';
import { useUsers } from 'hooks/users';
import { getUsers } from 'store/modules/users/ducks';
import { Court } from 'interfaces/courts';
import api from 'services/api';
import { modalStyle } from 'components/ModalStyle';
import { IDayUse, IResponseDayUse } from 'interfaces/DayUse';
import { AxiosResponse } from 'axios';
import getDayOfWeekDescription from 'utils/getDayOfWeekDescription';
import formatHour from 'utils/formatHour';
import { BsSunrise } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { Divider } from '@material-ui/core';
import formatDate from 'utils/formatDate';
import ConfirmDialog from 'components/ConfirmDialog';
import { FaUndo } from 'react-icons/fa';
import {
  Container,
  Option,
  OptionsContainer,
  TableContent,
  ContainerActions,
} from './styles';
import DayUseModal from './DayUseModal';

interface RadioTypes {
  id: string;
  label: string;
  value: string;
}

const DayUse: React.FC = () => {
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [idDayuse, setIdDayuse] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalEditOpen, setModalEditOpen] = React.useState(false);
  const [time, setTime] = useState('1');
  const [court, setCourt] = useState<Court>({} as Court);
  const history = useHistory();
  const [dayUse, setDayUse] = React.useState<IDayUse[]>([]);
  const [dayUseFiltered, setDayUseFiltered] = React.useState<IDayUse[]>([]);
  const [radioTypes, setRadioTypes] = useState<RadioTypes[]>([
    {
      id: '1',
      label: 'Antigos',
      value: '1',
    },
    {
      id: '2',
      label: 'Próximos',
      value: '2',
    },
  ] as RadioTypes[]);
  const { setPageTitle } = useMenu();
  const FilterDateSmalle = useCallback((data: IDayUse[]) => {
    function converteData(DataDDMMYY: string) {
      const dataSplit = DataDDMMYY.split('/');
      const novaData = new Date(
        parseInt(dataSplit[2], 10),
        parseInt(dataSplit[1], 10) - 1,
        parseInt(dataSplit[0], 10)
      );

      return novaData;
    }
    const dataInicial = new Date();
    const objetosFiltrados = data.filter((result) => {
      const currentDate = new Date(result.start_date);
      return converteData(currentDate.toLocaleDateString()) <= dataInicial;
    });
    setDayUseFiltered(objetosFiltrados);
  }, []);

  const FilterDate = useCallback(
    (RadioOptionId: string) => {
      function converteData(DataDDMMYY: string) {
        const dataSplit = DataDDMMYY.split('/');
        const novaData = new Date(
          parseInt(dataSplit[2], 10),
          parseInt(dataSplit[1], 10) - 1,
          parseInt(dataSplit[0], 10)
        );

        return novaData;
      }
      const dataInicial = new Date();
      if (RadioOptionId === '1') {
        const objetosFiltrados = dayUse.filter((result) => {
          const currentDate = new Date(result.start_date);
          return (
            converteData(currentDate.toLocaleDateString('pt-BR')) <= dataInicial
          );
        });
        setDayUseFiltered(objetosFiltrados);
      } else {
        const objetosFiltrados = dayUse.filter((result) => {
          const currentDate = new Date(result.start_date);
          return (
            converteData(currentDate.toLocaleDateString('pt-BR')) >= dataInicial
          );
        });
        setDayUseFiltered(objetosFiltrados);
      }

      setTime(RadioOptionId);
    },
    [setTime, dayUse]
  );

  const loadDayUse = useCallback(async () => {
    setLoading(true);
    await api
      .get(`/dayuse/findAll?limit=5&past=true`)
      // eslint-disable-next-line consistent-return
      .then((response: AxiosResponse<IResponseDayUse>) => {
        if (response.data.dayuse.length === 0) {
          setDayUse([]);
          setLoading(false);
          return [];
        }
        const responseFormat = response.data.dayuse.map((item, index) => {
          const startDate = new Date(item.start_date);
          const finishDate = new Date(item.finish_date);
          const hour = `${formatHour(startDate.getHours())}  até ${formatHour(
            finishDate.getHours()
          )}`;
          const dayOffWeek = getDayOfWeekDescription(startDate.getDay());
          return {
            ...item,
            dayOffWeek,
            hour,
          };
        }) as IDayUse[];

        setDayUse(responseFormat);
        FilterDateSmalle(responseFormat);
        setLoading(false);
      });
  }, [FilterDateSmalle]);
  useLayoutEffect(() => {
    loadDayUse();
    setPageTitle('Day Use');
  }, [loadDayUse, setPageTitle]);

  const handleOpenEditModal = useCallback(
    (courtSelected) => {
      setCourt(courtSelected);
      setModalEditOpen(true);
    },
    [setCourt]
  );
  const handleView = useCallback(
    (courtSelected) => {
      history.push(`/usersInDayUse?id_day_use=${courtSelected.id}`);
      setCourt(courtSelected);
      setModalEditOpen(true);
    },
    [history]
  );

  const closeModalAndRefresh = useCallback(() => {
    setModalOpen(false);
    setLoading(true);
    loadDayUse();
  }, [loadDayUse]);

  const handleOpenConfirm = useCallback((row: IDayUse) => {
    setIdDayuse(row.id);
    setIsOpenDialog(true);
  }, []);
  const handleCloseConfirm = useCallback(() => {
    setIsOpenDialog(false);
  }, []);
  const handleConfirm = useCallback(async () => {
    try {
      setLoading(true);
      await api
        .delete(`/dayuse/deleteDayUse?id_dayuse=${idDayuse}`)
        // eslint-disable-next-line consistent-return
        .then((response: AxiosResponse) => {
          toast.success('Day use Deletado Com sucesso !');
          setLoading(false);
          setIsOpenDialog(false);
          loadDayUse();
        });
    } catch (error) {
      // console.log(error);
    }
  }, [idDayuse, loadDayUse]);

  return (
    <Container>
      <FullLoader show={loading} />
      <ContainerActions>
        <div className="flex column">
          <OptionsContainer>
            {radioTypes.map((item) => (
              <Option
                key={item.id}
                selected={time === item.id}
                onClick={() => FilterDate(item.value)}
              >
                <button type="button" />
                <p>{item.label}</p>
              </Option>
            ))}
          </OptionsContainer>
          <button
            className="reload"
            onClick={() => loadDayUse()}
            type="button"
            title="Atualizar"
          >
            <FaUndo size={20} color="#000" />
          </button>
        </div>
        <button
          onClick={() => setModalOpen(true)}
          className="addClientButton"
          type="button"
        >
          <BsSunrise size={20} color="#fff" />
          <span>Criar Day Use</span>
        </button>
      </ContainerActions>
      <Divider />
      <TableContent>
        <Table<IDayUse>
          defaultNumberOfRows={10}
          columns={[
            {
              title: 'Dia',
              type: 'string',
              orderable: true,
              props: ['dayOffWeek'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'left',
              },
              cssProps: {
                width: '70%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
            },
            {
              title: 'Data',
              type: 'date',
              orderable: true,
              props: ['start_date'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'left',
              },
              cssProps: {
                width: '70%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
            },
            {
              title: 'Horário ',
              type: 'string',
              orderable: true,
              props: ['hour'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'left',
              },
              cssProps: {
                width: '70%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
            },
            {
              title: 'Vagas ',
              type: 'string',
              orderable: true,
              props: ['limit'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'left',
              },
              cssProps: {
                width: '70%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
            },
            {
              title: 'Clientes',
              type: 'string',
              orderable: true,
              props: ['users_in_list'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'left',
              },
              cssProps: {
                width: '70%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
            },
            {
              // cssRow:{
              //   width: 'auto';
              // },
              delimiter: '3',
              title: 'Editar',
              type: 'string',
              // orderable: true,
              props: ['id'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'center',
              },
              cssProps: {
                width: '2%',
                paddingRight: '8px',
                paddingLeft: '16px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
              renderItem: (row) => {
                return (
                  <button
                    type="button"
                    onClick={() => handleOpenEditModal(row)}
                  >
                    <MdEdit color="#286738" size={20} />
                  </button>
                );
              },
            },
            {
              // cssRow:{
              //   width: 'auto';
              // },
              delimiter: '3',
              title: 'Visualizar',
              type: 'string',
              // orderable: true,
              props: ['id'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'center',
              },
              cssProps: {
                width: '2%',
                paddingRight: '8px',
                paddingLeft: '16px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
              renderItem: (row) => {
                return (
                  <button type="button" onClick={() => handleView(row)}>
                    <MdRemoveRedEye color="#004774" size={20} />
                  </button>
                );
              },
            },
            {
              // cssRow:{
              //   width: 'auto';
              // },
              delimiter: '3',
              title: 'Excluir',
              type: 'string',
              // orderable: true,
              props: ['id'],
              cssTitle: {
                justifyContent: 'center',
                textAlign: 'center',
              },
              cssProps: {
                width: '2%',
                paddingRight: '8px',
                paddingLeft: '16px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
              renderItem: (row) => {
                return (
                  <button type="button" onClick={() => handleOpenConfirm(row)}>
                    <MdDeleteForever color="#d32727" size={20} />
                  </button>
                );
              },
            },
          ]}
          rows={dayUseFiltered}
        />
      </TableContent>
      <Modal isOpen={modalOpen} style={modalStyle}>
        <DayUseModal
          closeModal={() => setModalOpen(false)}
          closeAndRefresh={() => closeModalAndRefresh()}
        />
      </Modal>
      <ConfirmDialog
        isOpen={isOpenDialog}
        onClose={handleCloseConfirm}
        handleConfirm={handleConfirm}
      />
    </Container>
  );
};

export default DayUse;
