/* eslint-disable func-names */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from 'components/CurrencyInput';
import { Court } from 'interfaces/courts';
import Input from 'components/Input';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import api from 'services/api';
import { toast } from 'react-toastify';
import makeAnimated from 'react-select/animated';

import Select from 'components/ReactSelect';
import { transformToSelect } from 'utils/toSelect';
import { currencyMask } from 'masks';
import { Container, Content, SectionHour } from './styles';

interface PageProps {
  closeModal: () => void;
  closeAndRefresh: () => void;
}

interface AddModalProps {
  courts: [string];
  novaData: Date;
  hourStart: string;
  hourEnd: string;
  price: string;
  limit: number;
}
interface SelectCourtsProps {
  value: string;
}
const schema = yup.object().shape({
  price: yup.string().required(),
  limit: yup.number().required(),
  // hourStart: yup.string().required(),
  // hourEnd: yup.string().required(),
});
interface PropsSelect {
  value: string;
  label: string;
}
const DayUseModal: React.FC<PageProps> = ({ closeModal, closeAndRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [courts, setCourts] = useState<PropsSelect[]>([] as PropsSelect[]);
  const [dataStartEvent, setDataStartEvent] = useState('');
  const [initialHour, setInitialHour] = useState('');
  const [finishHour, setFinishHour] = useState('');
  const [selectedCourts, setSelectedCourts] = useState<SelectCourtsProps[]>([]);
  const InputData = useRef(null);
  const inputHoraInicial = useRef(null);
  const InputHoraFinal = useRef(null);
  const selectCourts = useRef(null);
  const [files, setFiles] = useState([]);
  const handleDrop = (acceptedFiles: any) =>
    setFiles(
      acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  const { register, handleSubmit, control, errors } = useForm<AddModalProps>({
    resolver: yupResolver(schema),
  });

  const handleChange = (value: any, action: any) => {
    if (action.action === 'select-option') {
      setSelectedCourts([...selectedCourts, action.option.value]);
    }
    if (action.action === 'remove-value') {
      setSelectedCourts(
        selectedCourts.filter((item) => item !== action.removedValue.value)
      );
    }
  };
  const onSubmit = useCallback(
    async (data: AddModalProps) => {
      try {
        if (!selectedCourts.length) {
          toast.warn('Você deve selecionar Pelomenos uma quadra !');
          // @ts-ignore
          selectCourts.current!.focus();
          return;
        }
        if (!dataStartEvent) {
          toast.warn('O campo de Data não pode estar vazio !');
          // @ts-ignore
          InputData.current!.focus();
          return;
        }
        if (!initialHour) {
          toast.warn(
            'O campo de hora inicial não pode estar vazio nao pode estar vazio !'
          );
          // @ts-ignore
          inputHoraInicial.current!.focus();
          return;
        }
        if (!finishHour) {
          toast.warn('O campo de Hora final não pode estar vazio !');
          // @ts-ignore
          InputHoraFinal.current!.focus();
          return;
        }
        const horaInicial = `${initialHour[0]}${initialHour[1]}`;
        const horaFinal = `${finishHour[0]}${finishHour[1]}`;
        const dataInicial = new Date(`${dataStartEvent}T00:00:00`);
        const price = data.price.replace('R$ ', '').replace(',', '.');
        const dataCreate = {
          courts: selectedCourts,
          price,
          start_date: new Date(dataInicial.setHours(Number(horaInicial))),
          finish_date: new Date(dataInicial.setHours(Number(horaFinal))),
          limit: data.limit,
        };
        api.post('/dayUse/create', dataCreate).then((response) => {
          if (response.status === 200) {
            toast.success('Day Use criado com sucesso!');
            closeAndRefresh();
          }
        });
      } catch (error) {
        toast.error('Erro!');
      }
    },
    [selectedCourts, dataStartEvent, initialHour, finishHour, closeAndRefresh]
  );
  useEffect(() => {
    api
      .get(`/courts/findAll?id_place=${process.env.REACT_APP_ID_PLACE}&page=1`)
      .then((response) => {
        const listCourts = transformToSelect([...response.data]);
        if (response.data.length <= 1) {
          setCourts(listCourts);
        } else {
          setCourts([...listCourts]);
        }
        setLoading(false);
      });
  }, []);
  return (
    <Container>
      <ModalHeader title="Novo Day Use" closeModal={closeModal} />
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Select
            className="select"
            label="Quadras"
            options={courts}
            closeMenuOnSelect
            onChange={handleChange}
            name="courts"
            isMulti
            ref={selectCourts}
          />
          <CurrencyInput
            control={control}
            label="Preço"
            name="price"
            prefix="R$ "
            register={register}
            error={!!errors.price?.message}
          />
          <Input
            title="Data inicial"
            label="Data inicial"
            type="date"
            name="createdOn"
            className="inputDateTime"
            register={InputData}
            onChange={(e) => setDataStartEvent(e.target.value)}
          />
          <SectionHour>
            <Input
              title="Começa às"
              label="Hora Inicial"
              type="time"
              className="inputDateTime"
              name="hourStart"
              register={inputHoraInicial}
              onChange={(e) => setInitialHour(e.target.value)}
            />
            <Input
              title="Termina às"
              label="Hora final"
              type="time"
              className="inputDateTime"
              name="hourEnd "
              register={InputHoraFinal}
              onChange={(e) => setFinishHour(e.target.value)}
            />
          </SectionHour>
          <Input
            title="Limite"
            label="Limite"
            name="limit"
            type="number"
            register={register}
            error={!!errors.limit?.message}
          />

          <Button type="submit">{loading ? 'Entrando...' : 'Criar'}</Button>
        </form>
      </Content>
    </Container>
  );
};

export default DayUseModal;
