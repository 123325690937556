import React, { useEffect, useCallback, useState, useMemo } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Input from 'components/Input';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import api from 'services/api';
import Select from 'components/ReactSelect';
import { Court } from 'interfaces/courts';
import { Sports } from 'interfaces/sports';
import { toast } from 'react-toastify';
import { Container, Content, LoadingText } from './styles';

interface PageProps {
  closeModal: () => void;
  closeAndRefresh: () => void;
  court: Court;
}

interface LastStepProps {
  nameCourt: string;
}
interface SelectSportProps {
  value: string;
  label: string;
}

const schema = yup.object().shape({
  nameCourt: yup.string().required(),
});

interface ISportsDTO {
  id: string;
  name: string;
}

interface ICourtsSportsDTO {
  id: string;
  name: string;
  sports: ISportsDTO[];
}

interface IUpdateCourtDTO {
  id: string;
  name: string;
  covered: boolean;
  sports: string[];
}

const EditCourtModal: React.FC<PageProps> = ({
  closeModal,
  closeAndRefresh,
  court,
}) => {
  const [loading, setLoading] = useState(true);
  const [courtName, setCourtName] = useState('');
  const [sports, setSports] = useState<Sports[]>([]);
  const [selectedSports, setSelectedSports] = useState<SelectSportProps[]>([]);
  const [selectedCoverade, setSelectCoverade] = useState<SelectSportProps>(
    {} as SelectSportProps
  );
  const [courtSport, setCourtSport] = useState<ICourtsSportsDTO[]>([]);

  const { register, handleSubmit, setValue, control, errors } =
    useForm<LastStepProps>({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    setCourtName(court.courtname);
    api.get('/sports/findAll').then((response) => {
      setSports(response.data);
      api
        .get(
          `/courts/findCourtSport?id_place=${process.env.REACT_APP_ID_PLACE}`
        )
        .then((response2) => {
          setCourtSport(response2.data);
          const tempArray: SelectSportProps[] = [];
          const tempCourtSport = response2.data.filter(
            (item: any) => item.id === court.id
          );
          tempCourtSport[0].sports.map((item: any) => {
            tempArray.push({ value: item.id, label: item.name });
            return null;
          });
          const coverade = {
            value: `${court.covered ? '1' : '0'}`,
            label: `${court.covered ? 'Sim' : 'Não'}`,
          };
          setSelectCoverade(coverade);
          setSelectedSports(tempArray);
          setLoading(false);
        });
    });
  }, [setValue, court]);

  const onSubmit = useCallback(() => {
    const sportsIds: string[] = [];
    selectedSports.map((item) => sportsIds.push(item.value));
    const data: IUpdateCourtDTO = {
      id: court.id,
      name: courtName,
      sports: sportsIds,
      covered: selectedCoverade.value === '1',
    };

    api.put('/courts/update', data).then(() => {
      toast.success('Quadra alterada com sucesso!');
      closeAndRefresh();
    });
  }, [closeAndRefresh, court, courtName, selectedSports, selectedCoverade]);

  const handleSelectSport = (selectedOption: any) => {
    setSelectedSports(selectedOption);
  };
  const handleSelectCoverade = (selectedOption: any) => {
    setSelectCoverade(selectedOption);
  };

  const options = useMemo(() => {
    const tempArray: any[] = [];
    sports.map((item) => {
      tempArray.push({ value: item.id, label: item.name });
      return null;
    });
    return tempArray;
  }, [sports]);
  const Converad = [
    {
      value: '1',
      label: 'Sim',
    },
    {
      value: '0',
      label: 'Não',
    },
  ];
  return (
    <Container>
      <ModalHeader title="Editar Quadra" closeModal={closeModal} />
      {loading ? (
        <LoadingText>Carregando...</LoadingText>
      ) : (
        <Content>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              title="Nome da quadra"
              label="Nome da quadra"
              name="nameCourt"
              register={register}
              value={courtName}
              onChange={(e) => setCourtName(e.target.value)}
              error={!!errors.nameCourt?.message}
            />
            <Select
              label="É coberta?"
              onChange={handleSelectCoverade}
              className="select"
              value={selectedCoverade}
              options={Converad}
            />
            <Select
              label="Esportes"
              onChange={handleSelectSport}
              className="select"
              isMulti
              value={selectedSports}
              options={options}
            />
            <Button type="submit">{loading ? 'Entrando...' : 'Salvar'}</Button>
          </form>
        </Content>
      )}
    </Container>
  );
};

export default EditCourtModal;
