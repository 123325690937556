/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import MaskedInput from 'react-text-mask';
import { Controller } from 'react-hook-form';

import white_label from 'white_label';
import { MdEdit } from 'react-icons/md';
import { FaSave } from 'react-icons/fa';
import FullLoader from 'components/FullLoader';

import { ActionButton, Container } from './styled';

const { secondary_color } = white_label();

interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  register?: any;
  error?: boolean;
  label?: string;
  name?: any;
  defaultValue?: any;
  textHint?: string;
  currency?: boolean;
  isEditable?: boolean;
  isEditing?: boolean;
  isLoadding?: boolean;
  save?(): void;
  handleEdit?(): void;
  saveDisable?: boolean;
}

const TextArea: React.FC<TextareaProps> = ({
  register,
  error,
  label,
  name,
  textHint,
  defaultValue,
  currency,
  isEditable,
  isEditing,
  save,
  handleEdit,
  saveDisable,
  isLoadding,
  ...rest
}) => {
  const handleEditing = React.useCallback(() => {
    handleEdit!();
  }, [handleEdit]);
  const handleSave = React.useCallback(() => {
    save!();
  }, [save]);
  return (
    <Container error={error}>
      <FullLoader show={isLoadding || false} />
      {label && <label>{label}</label>}
      {isEditable && (
        <div className="containerButton">
          {isEditing ? (
            <ActionButton
              saveDisable={saveDisable}
              disabled={saveDisable}
              type="button"
              className="editButton"
              title="Salvar"
              onClick={handleSave}
            >
              <FaSave
                color={saveDisable ? '#838383' : secondary_color}
                size={20}
              />
            </ActionButton>
          ) : (
            <ActionButton
              type="button"
              className="editButton"
              title="Editar"
              onClick={handleEditing}
            >
              <MdEdit color={secondary_color} size={20} />
            </ActionButton>
          )}
        </div>
      )}

      <textarea
        name={name}
        defaultValue={defaultValue}
        ref={register}
        {...rest}
      />
      {textHint && <span className="text-hint">{textHint}</span>}
    </Container>
  );
};

export default TextArea;
