/* eslint-disable radix */
/* eslint-disable func-names */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from 'components/CurrencyInput';
import Input from 'components/Input';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import api from 'services/api';
import { toast } from 'react-toastify';

import Select from 'components/ReactSelect';
import { transformToSelect } from 'utils/toSelect';
import { FidelityProps } from 'interfaces/fidelity';
import TextArea from 'components/TextArea';
import { Container, Content, Divider, SectionHour } from './styles';

interface PageProps {
  closeModal: () => void;
  closeAndRefresh: () => void;
  dataEdit: FidelityProps;
}

interface AddModalProps {
  description: string;
  points: string;
  price: string;
  value: string;
}
interface SelectCourtsProps {
  value: string;
}
const schema = yup.object().shape({
  price: yup.string().required(),
  description: yup.string().required(),
  value: yup.string().required(),
  points: yup.string().required(),
});
interface PropsSelect {
  value: string;
  label: string;
}
const FidelityModal: React.FC<PageProps> = ({
  closeModal,
  closeAndRefresh,
  dataEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [modules, setModules] = useState<PropsSelect[]>([] as PropsSelect[]);
  const [selectedModule, setSelectedModule] = useState<PropsSelect>(
    {} as PropsSelect
  );
  const { register, handleSubmit, control, errors, setValue } =
    useForm<AddModalProps>({
      resolver: yupResolver(schema),
    });

  const selectCourts = useRef(null);
  useEffect(() => {
    api.get(`/score/findAllModules`).then((response) => {
      const responseModules = transformToSelect([...response.data]);
      if (response.data.length <= 1) {
        setModules(responseModules);
      } else {
        setModules([...responseModules]);
        if (dataEdit) {
          const optionSelected = responseModules.filter(
            (item: PropsSelect) => item.label === dataEdit.module
          );
          setSelectedModule(optionSelected);
        }
      }
      setLoading(false);
    });

    if (dataEdit.id) {
      setDescription(dataEdit.description);
      setValue('price', parseFloat(dataEdit.price));
      setValue('value', parseFloat(dataEdit.each_point_worth));
      setValue('points', dataEdit.points);
    } else {
      setValue('description', '');
      setValue('price', '');
      setValue('value', '');
      setValue('points', '');
    }
  }, [dataEdit, setValue]);

  const handleChange = (value: any, action: any) => {
    if (action.action === 'select-option') {
      setSelectedModule(value);
    }
  };
  const onSubmit = useCallback(
    async (data: AddModalProps) => {
      try {
        console.log(selectedModule);
        if (!selectedModule) {
          toast.warn('Você deve selecionar Pelomenos um Modulo !');
          // @ts-ignore
          selectCourts.current!.focus();
          return;
        }
        const priceFormat = parseInt(data.price.replace('R$', ''));
        const valueFormat = parseFloat(data.value.replace('R$', ''));
        const dataCreate = {
          id_module: selectedModule.value,
          price: priceFormat,
          points: parseInt(data.points),
          each_point_worth: valueFormat,
          description,
        };
        const dataUpdate = {
          id: dataEdit.id,
          id_module: selectedModule.value,
          price: priceFormat,
          points: parseInt(data.points),
          each_point_worth: valueFormat,
          description,
        };
        if (dataEdit.id) {
          api.put('/score/updateScoreRule', dataUpdate).then((response) => {
            if (response.status === 200) {
              toast.success('Regra alterda com sucesso!');
              closeAndRefresh();
            }
          });
        } else {
          api.post('/score/createScoreRule', dataCreate).then((response) => {
            if (response.status === 200) {
              toast.success('Regra criada com sucesso!');
              closeAndRefresh();
            }
          });
        }
      } catch (error) {
        toast.error('Erro!');
      }
    },
    [selectedModule, dataEdit, closeAndRefresh, description]
  );
  return (
    <Container>
      <ModalHeader
        title={dataEdit ? 'Editar Regra ' : 'Nova Regra'}
        closeModal={closeModal}
      />
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Select
            className="select"
            label="Modulo"
            options={modules}
            // @ts-ignore
            value={selectedModule}
            closeMenuOnSelect
            onChange={handleChange}
          />
          <CurrencyInput
            control={control}
            label="Preço"
            name="price"
            prefix="R$ "
            register={register}
            error={!!errors.price?.message}
          />
          <Input
            title="Pontos"
            label="Pontos"
            name="points"
            type="string"
            register={register}
            error={!!errors.points?.message}
          />
          <CurrencyInput
            control={control}
            label="Cada ponto vale"
            name="value"
            prefix="R$ "
            register={register}
            error={!!errors.value?.message}
          />
          <Divider />
          {/* <Input
            title="Descrição"
            label="Descrição"
            name="description"
            type="string"
            register={register}
            error={!!errors.description?.message}
          /> */}
          <TextArea
            isLoadding={loading}
            rows={5}
            cols={100}
            defaultValue={description}
            name="description"
            onChange={(e) => setDescription(e.target.value)}
          />
          <Button type="submit">{dataEdit.id ? 'Salvar...' : 'Criar'}</Button>
        </form>
      </Content>
    </Container>
  );
};

export default FidelityModal;
