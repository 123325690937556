import styled from 'styled-components';
import white_label from 'white_label';

const { secondary_color } = white_label();

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 87vh;
  overflow-y: auto;
  background-color: #fff;
  flex-direction: column;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  ::-webkit-scrollbar {
    width: 2px;
    height: 10px;
    background-color: #cccccc;
  }
  ::-webkit-scrollbar-track {
    background: #cccccc;
    width: 3px;
  }
  ::-webkit-scrollbar-track-piece {
    background: #cccccc;
  }
  ::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 5px;
    width: 3px;
  }

  .section {
    padding: 18px;
  }
  .CopyCourt {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: ${secondary_color};
    border: 0;
    transition: 0.3s all;
    margin-right: 10px;
    span {
      margin-left: 20px;
      color: #fff;
    }
  }

  .colapseTable {
    border-collapse: collapse;
    margin: 28px;
  }
  .titleTable {
    border-left: 1px solid #00000024;
    border-right: 1px solid #00000024;
    color: ${secondary_color};
  }
  .c {
    transform: rotate(45deg);
    transform: rotate(303deg);
    font-size: 24px;
    width: 71px;
  }

  .cellTable {
    cursor: pointer;
    min-width: 60px;
    text-align: center;
    text-align: center;
    padding: 11px 0px;
    border: 1px solid #00000024;
    /* background: #cad3ff; */
    transition: 0.5s;
    &:hover {
      transform: scale(1.1);
      border-radius: 5px;
    }
    .addPriceButton {
      margin: auto;
    }
  }
  .pagination-container {
    display: flex;
    justify-content: flex-end;
    margin: 22px 22px 22px 0;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .pagination-options {
      display: flex;
      margin-right: 90px;
      align-items: center;

      @media (max-width: 768px) {
        margin-left: 12px;
      }

      p {
        color: #7a8799;
      }

      select {
        margin-left: 22px;
        border: none;
        background-color: #fff;
        color: #7a8799;
      }
    }
  }

  .table-container {
    overflow-y: auto;
  }
  button {
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .options {
    svg {
      & + svg {
        margin-left: 12px;
      }
    }
  }
`;
