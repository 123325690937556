/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useCallback, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { MdAdd, MdEdit, MdDeleteForever } from 'react-icons/md';

import FullLoader from 'components/FullLoader';
import Pagination from 'components/Pagination';
import { modalStyle } from 'components/ModalStyle';
import { Table } from '@pdasolucoes/web';
import { useUsers } from 'hooks/users';
import api from 'services/api';

import { toast } from 'react-toastify';
import { useMenu } from 'context';
import { FaCheck, FaMinusCircle } from 'react-icons/fa';
import ConfirmDialog from 'components/ConfirmDialog';
import AddRuleModal from './AddRulesModal';
import { Container, TableContent } from './styles';
import EditRuleModal from './EditRulesModal';

interface RuleProps {
  id: string;
  hour: number;
  minutes: number;
  percentage: number;
  active: boolean;
  created_at: string;
  updated_at: string;
}

const CancellationRules: React.FC = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [rules, setRules] = useState<RuleProps[]>([]);
  const [selectedRule, setSelectedRule] = useState<RuleProps>({} as RuleProps);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [ruleId, setIdRuleId] = React.useState('');
  const dispatch = useDispatch();
  const { setPageTitle } = useMenu();

  const loadRules = useCallback(() => {
    api.get(`/cancelationRules`).then((response) => {
      setRules(response.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setPageTitle('Regras de cancelamento');
    loadRules();
  }, [setPageTitle, loadRules]);

  const handleOpenEditModal = useCallback((item: RuleProps) => {
    setSelectedRule(item);
    setEditModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    loadRules();
    setAddModalOpen(false);
    setEditModalOpen(false);
  }, [loadRules]);

  const handleOpenConfirm = useCallback((row: RuleProps) => {
    setIdRuleId(row.id);
    setIsOpenDialog(true);
  }, []);

  const handleCloseConfirm = useCallback(() => {
    setIsOpenDialog(false);
  }, []);

  const handleConfirm = useCallback(async () => {
    try {
      setLoading(true);
      api.delete(`/cancelationRules?id=${ruleId}`).then(() => {
        toast.success('Regra apagada com sucesso !');
        loadRules();
        setLoading(false);
        setIsOpenDialog(false);
      });
    } catch (error) {
      console.log(error);
    }
  }, [loadRules, ruleId]);

  return (
    <Container>
      <FullLoader show={loading} />
      <button
        onClick={() => setAddModalOpen(true)}
        className="addPackageButton"
        type="button"
      >
        <MdAdd size={20} color="#fff" />
        <span>Adicionar regra</span>
      </button>
      <TableContent>
        <Table<RuleProps>
          defaultNumberOfRows={10}
          columns={[
            {
              title: 'Hora',
              type: 'string',
              orderable: true,
              props: ['hour'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'left',
              },
              cssProps: {
                width: '70%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
            },
            {
              title: 'Minuto',
              type: 'string',
              orderable: true,
              props: ['minutes'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'left',
              },
              cssProps: {
                width: '70%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
            },
            {
              title: 'Porcentagem %',
              type: 'string',
              orderable: true,
              props: ['percentage'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'left',
              },
              cssProps: {
                width: '70%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
            },
            {
              title: 'Ativo ',
              type: 'string',
              orderable: true,
              props: ['limit'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'left',
              },
              cssProps: {
                width: '70%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
              renderItem: (row) => {
                if (row.active) {
                  return <FaCheck color="#57ff41" size={20} />;
                }

                return <FaMinusCircle color="#cc0000" size={20} />;
              },
            },
            {
              // cssRow:{
              //   width: 'auto';
              // },
              delimiter: '3',
              title: 'Editar',
              type: 'string',
              // orderable: true,
              props: ['id'],
              cssTitle: {
                justifyContent: 'center',
                textAlign: 'center',
              },
              cssProps: {
                width: '50%',
                paddingRight: '8px',
                paddingLeft: '16px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
              renderItem: (row) => {
                return (
                  <button
                    type="button"
                    onClick={() => handleOpenEditModal(row)}
                  >
                    <MdEdit color="#286738" size={20} />
                  </button>
                );
              },
            },
            {
              // cssRow:{
              //   width: 'auto';
              // },
              delimiter: '3',
              title: 'Excluir',
              type: 'string',
              // orderable: true,
              props: ['id'],
              cssTitle: {
                justifyContent: 'center',
                textAlign: 'center',
              },
              cssProps: {
                width: '2%',
                paddingRight: '8px',
                paddingLeft: '16px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
              renderItem: (row) => {
                return (
                  <button type="button" onClick={() => handleOpenConfirm(row)}>
                    <MdDeleteForever color="#d32727" size={20} />
                  </button>
                );
              },
            },
          ]}
          rows={rules}
        />
      </TableContent>
      <Modal isOpen={addModalOpen} style={modalStyle}>
        <AddRuleModal closeModal={() => handleCloseModal()} />
      </Modal>
      <Modal isOpen={editModalOpen} style={modalStyle}>
        <EditRuleModal
          initialData={selectedRule}
          closeModal={() => handleCloseModal()}
        />
      </Modal>
      <ConfirmDialog
        title="Deseja realmente excluir este Esporte?"
        isOpen={isOpenDialog}
        onClose={handleCloseConfirm}
        handleConfirm={handleConfirm}
      />
    </Container>
  );
};

export default CancellationRules;
