import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from 'components/CurrencyInput';

import Input from 'components/Input';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import api from 'services/api';

import { toast } from 'react-toastify';
import { Container, Content } from './styles';

interface PageProps {
  closeModal: () => void;
}

interface LastStepProps {
  amount: number;
  price: string;
}

const schema = yup.object().shape({
  amount: yup.number().required(),
  price: yup.string().required(),
});

const AddPackageModal: React.FC<PageProps> = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, control, errors } = useForm<LastStepProps>({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    ({ amount, price }: LastStepProps) => {
      const data = {
        amount,
        price,
      };

      api.post('/payers/createPackage', data).then((response) => {
        toast.success('Pacote criado com sucesso!');
        closeModal();
      });
    },
    [closeModal]
  );

  return (
    <Container>
      <ModalHeader title="Novo pacote" closeModal={closeModal} />
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Quantidade de aulas"
            name="amount"
            register={register}
            error={!!errors.amount?.message}
          />
          <CurrencyInput
            control={control}
            label="Preço"
            name="price"
            prefix="R$ "
            register={register}
            error={!!errors.price?.message}
          />
          <Button type="submit">{loading ? 'Entrando...' : 'Salvar'}</Button>
        </form>
      </Content>
    </Container>
  );
};

export default AddPackageModal;
