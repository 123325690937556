import styled from 'styled-components';

export const Container = styled.div`
  height: 90%;
`;

export const Content = styled.div`
  height: 100%;
  margin-top: 40px;
  form {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .exceptionsContainer {
    width: 40%;
    margin-right: auto;
    margin-top: 8px;
    .content {
      display: flex;
      dlex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;
    }

    p {
      font-size: 16px;
      color: #999;
    }
    span {
      margin-left: 8px;
    }
    button {
      margin-left: 8px;
      height: 20px;
      width: 20px;
      background: none;
      border: 0;
    }
  }
  .buttonsContainer {
    width: 100%;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .addExceptionButton {
      width: 40%;
      margin-right: 8px;
      font-size: 14px;
      border-radius: 8px;
      height: 52px;
      border: none;
    }
  }
`;
