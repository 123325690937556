/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useEffect,
  useCallback,
  useState,
  useLayoutEffect,
} from 'react';
import { MdDeleteForever, MdEdit, MdRemoveRedEye } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import Modal from 'react-modal';

import { Table } from '@pdasolucoes/web';
import FullLoader from 'components/FullLoader';

import { useMenu } from 'context';
import { Court } from 'interfaces/courts';
import api from 'services/api';
import { modalStyle } from 'components/ModalStyle';
import { IDayUse, IResponseDayUse } from 'interfaces/DayUse';
import { AxiosResponse } from 'axios';
import getDayOfWeekDescription from 'utils/getDayOfWeekDescription';
import formatHour from 'utils/formatHour';
import { BsSunrise } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { Divider } from '@material-ui/core';
import formatDate from 'utils/formatDate';
import ConfirmDialog from 'components/ConfirmDialog';
import { FaInfoCircle, FaStar } from 'react-icons/fa';
import { FidelityProps } from 'interfaces/fidelity';
import {
  Container,
  Option,
  OptionsContainer,
  TableContent,
  ContainerActions,
  Description,
} from './styles';
import FidelityModal from './FidelityModal';

interface RadioTypes {
  id: string;
  label: string;
  value: string;
}

const Fidelity: React.FC = () => {
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [idScoreRule, setIdScoreRule] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalEditOpen, setModalEditOpen] = React.useState(false);
  const [time, setTime] = useState('1');
  const [ruleScore, setRuleScore] = useState<FidelityProps>(
    {} as FidelityProps
  );
  const history = useHistory();
  const [data, setData] = React.useState<FidelityProps[]>([]);
  const [dayUseFiltered, setDayUseFiltered] = React.useState<IDayUse[]>([]);
  const [radioTypes, setRadioTypes] = useState<RadioTypes[]>([
    {
      id: '1',
      label: 'Antigos',
      value: '1',
    },
    {
      id: '2',
      label: 'Próximos',
      value: '2',
    },
  ] as RadioTypes[]);
  const { setPageTitle } = useMenu();
  const FilterDateSmalle = useCallback((dataF: IDayUse[]) => {
    function converteData(DataDDMMYY: string) {
      const dataSplit = DataDDMMYY.split('/');
      const novaData = new Date(
        parseInt(dataSplit[2], 10),
        parseInt(dataSplit[1], 10) - 1,
        parseInt(dataSplit[0], 10)
      );

      return novaData;
    }
    const dataInicial = new Date();
    const objetosFiltrados = dataF.filter((result) => {
      const currentDate = new Date(result.start_date);
      return converteData(currentDate.toLocaleDateString()) <= dataInicial;
    });
    setDayUseFiltered(objetosFiltrados);
  }, []);

  const FilterDate = useCallback(
    (RadioOptionId: string) => {
      function converteData(DataDDMMYY: string) {
        const dataSplit = DataDDMMYY.split('/');
        const novaData = new Date(
          parseInt(dataSplit[2], 10),
          parseInt(dataSplit[1], 10) - 1,
          parseInt(dataSplit[0], 10)
        );

        return novaData;
      }
      const dataInicial = new Date();
      // if (RadioOptionId === '1') {
      //   const objetosFiltrados = dayUse.filter((result) => {
      //     const currentDate = new Date(result.start_date);
      //     return (
      //       converteData(currentDate.toLocaleDateString('pt-BR')) <= dataInicial
      //     );
      //   });
      //   setDayUseFiltered(objetosFiltrados);
      // } else {
      //   const objetosFiltrados = dayUse.filter((result) => {
      //     const currentDate = new Date(result.start_date);
      //     return (
      //       converteData(currentDate.toLocaleDateString('pt-BR')) >= dataInicial
      //     );
      //   });
      //   setDayUseFiltered(objetosFiltrados);
      // }

      setTime(RadioOptionId);
    },
    [setTime]
  );

  const loadRules = useCallback(async () => {
    await api
      .get(`/score/findAllScoreRules`)
      // eslint-disable-next-line consistent-return
      .then((response: AxiosResponse<FidelityProps[]>) => {
        if (response.data) {
          setData(response.data);
          setLoading(false);
          return [];
        }
        // const responseFormat = response.data.dayuse.map((item, index) => {
        //   const startDate = new Date(item.start_date);
        //   const finishDate = new Date(item.finish_date);
        //   const hour = `${formatHour(startDate.getHours())}  até ${formatHour(
        //     finishDate.getHours()
        //   )}`;
        //   const dayOffWeek = getDayOfWeekDescription(startDate.getDay());
        //   return {
        //     ...item,
        //     dayOffWeek,
        //     hour,
        //   };
        // }) as IDayUse[];

        // setDayUse(responseFormat);
        // FilterDateSmalle(responseFormat);
        setLoading(false);
      });
  }, []);
  useLayoutEffect(() => {
    loadRules();
    setPageTitle('Fidelidade');
  }, [loadRules, setPageTitle]);

  const handleOpenEditModal = useCallback(
    (ruleSelected) => {
      setRuleScore(ruleSelected);
      setModalEditOpen(true);
    },
    [setRuleScore]
  );

  const closeModalAndRefresh = useCallback(() => {
    setModalEditOpen(false);
    setLoading(true);
    loadRules();
  }, [loadRules]);

  const handleOpenConfirm = useCallback((row: FidelityProps) => {
    setIdScoreRule(row.id);
    setIsOpenDialog(true);
  }, []);
  const handleCloseConfirm = useCallback(() => {
    setIsOpenDialog(false);
  }, []);
  const handleConfirm = useCallback(async () => {
    try {
      setLoading(true);
      await api
        .delete(`/score/deleteScoreRule?id=${idScoreRule}`)
        // eslint-disable-next-line consistent-return
        .then((response: AxiosResponse) => {
          toast.success('Regra Apagada Com sucesso !');
          setLoading(false);
          setIsOpenDialog(false);
          loadRules();
        });
    } catch (error) {
      // console.log(error);
    }
  }, [idScoreRule, loadRules]);

  return (
    <Container>
      <FullLoader show={loading} />
      <button
        onClick={() => setModalEditOpen(true)}
        className="addClientButton"
        type="button"
      >
        <FaStar size={20} color="#fff" />
        <span>Criar</span>
      </button>
      <Divider />
      <TableContent>
        <Table<FidelityProps>
          defaultNumberOfRows={10}
          columns={[
            {
              title: 'Módulo',
              type: 'string',
              orderable: true,
              props: ['module'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'left',
              },
              cssProps: {
                width: '70%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
            },
            {
              title: 'Preço',
              type: 'currency',
              orderable: true,
              props: ['price'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'left',
              },
              cssProps: {
                width: '70%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
            },
            {
              title: 'Pontos ',
              type: 'number',
              orderable: true,
              props: ['points'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'left',
              },
              cssProps: {
                width: '70%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
            },
            {
              title: 'Cada ponto vale ',
              type: 'currency',
              orderable: true,
              props: ['each_point_worth'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'left',
              },
              cssProps: {
                width: '70%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
            },
            {
              title: 'Descrição',
              type: 'string',
              orderable: true,
              props: ['description'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'left',
              },
              cssProps: {
                width: '70%',
                paddingRight: '8px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
              renderItem: (row) => {
                return row.description.length > 40 ? (
                  <Description>
                    <span>
                      {row.description.substring(0, 41)}
                      ...
                    </span>
                    <div className="more_description">
                      <div>
                        <FaInfoCircle
                          color="#cb5223"
                          size={17}
                          className="info"
                        />
                        <div className="card">
                          <p>{row.description}</p>
                        </div>
                      </div>
                    </div>
                  </Description>
                ) : (
                  <Description>{row.description}</Description>
                );
              },
            },
            {
              // cssRow:{
              //   width: 'auto';
              // },
              delimiter: '3',
              title: 'Editar',
              type: 'string',
              // orderable: true,
              props: ['id'],
              cssTitle: {
                justifyContent: 'flex-start',
                textAlign: 'center',
              },
              cssProps: {
                width: '2%',
                paddingRight: '8px',
                paddingLeft: '16px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
              renderItem: (row) => {
                return (
                  <button
                    type="button"
                    onClick={() => handleOpenEditModal(row)}
                  >
                    <MdEdit color="#286738" size={20} />
                  </button>
                );
              },
            },
            {
              // cssRow:{
              //   width: 'auto';
              // },
              delimiter: '3',
              title: 'Excluir',
              type: 'string',
              // orderable: true,
              props: ['id'],
              cssTitle: {
                justifyContent: 'center',
                textAlign: 'center',
              },
              cssProps: {
                width: '2%',
                paddingRight: '8px',
                paddingLeft: '16px',
                fontFamily: 'sans-serif',
              },
              cssText: {
                paddingLeft: '8px',
              },
              renderItem: (row) => {
                return (
                  <button type="button" onClick={() => handleOpenConfirm(row)}>
                    <MdDeleteForever color="#d32727" size={20} />
                  </button>
                );
              },
            },
          ]}
          rows={data}
        />
      </TableContent>
      <Modal isOpen={modalEditOpen} style={modalStyle}>
        <FidelityModal
          closeModal={() => setModalEditOpen(false)}
          closeAndRefresh={() => closeModalAndRefresh()}
          dataEdit={ruleScore}
        />
      </Modal>
      <ConfirmDialog
        title="Deseja realmente apagar a Regra ?"
        isOpen={isOpenDialog}
        onClose={handleCloseConfirm}
        handleConfirm={handleConfirm}
      />
    </Container>
  );
};

export default Fidelity;
