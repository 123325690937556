import React, { useEffect, useCallback, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from 'components/CurrencyInput';

import TextArea from 'components/TextArea';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import { PackagesProps } from 'interfaces/packages';
import api from 'services/api';

import { toast } from 'react-toastify';
import Input from 'components/Input';
import Select from 'components/ReactSelect';
import { Container, Content } from './styles';

interface RuleProps {
  id: string;
  hour: number;
  minutes: number;
  percentage: number;
  active: boolean;
  created_at: string;
  updated_at: string;
}
interface LastStepProps {
  hour: number;
  minutes: number;
  percentage: number;
}

interface SelectProps {
  value: string;
  label: string;
}
interface PageProps {
  initialData: RuleProps;
  closeModal: () => void;
}
const schema = yup.object().shape({
  hour: yup.string().required(),
  minutes: yup.string().required(),
});
const EditRuleModal: React.FC<PageProps> = ({ initialData, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [percentage, setPercentage] = useState<number>();
  const [selectedOption, setSelectedOption] = useState<SelectProps>();

  const { register, handleSubmit, setValue, errors } = useForm<LastStepProps>({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    (data: LastStepProps) => {
      try {
        api
          .put('/cancelationRules', {
            id: initialData.id,
            hour: data.hour,
            minutes: data.minutes,
            percentage: data.percentage,
            active: isActive,
          })
          .then((response) => {
            toast.success('Regra atualizada com sucesso!');
            closeModal();
          });
      } catch (error) {
        console.log(error);
      }
    },
    [closeModal, initialData.id, isActive]
  );
  const optionsSelect = [
    { value: 'true', label: 'Sim' },
    { value: 'false', label: 'Não' },
  ];
  const handleChange = (value: any, action: any) => {
    setSelectedOption(value);
    if (value.value === 'true') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };
  const handleChangePorcentagem = () => {
    console.log(percentage);
    // @ts-ignore
    if (percentage > 100) {
      setPercentage(100);
    }
  };
  useEffect(() => {
    setValue('hour', initialData.hour);
    setValue('minutes', initialData.minutes);
    setPercentage(initialData.percentage);
    setSelectedOption({
      value: `${initialData.active}`,
      label: initialData.active ? 'Sim' : 'Não',
    });
  }, [setValue, initialData]);
  return (
    <Container>
      <ModalHeader title="Editar regra" closeModal={closeModal} />
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            title="Hora"
            type="number"
            name="hour"
            register={register}
            error={!!errors.hour?.message}
            autoCapitalize="words"
          />
          <Input
            title="Minuto"
            type="number"
            name="minutes"
            register={register}
            error={!!errors.minutes?.message}
            autoCapitalize="words"
          />
          <Input
            title="Porcentagem"
            type="number"
            name="percentage"
            value={percentage}
            max={100}
            onChange={(e) => setPercentage(Number(e.target.value))}
            onKeyDown={handleChangePorcentagem}
          />
          <Select
            className="select"
            label="Ativo"
            options={optionsSelect}
            closeMenuOnSelect
            value={selectedOption}
            onChange={handleChange}
          />
          <Button type="submit">{loading ? 'Entrando...' : 'Salvar'}</Button>
        </form>
      </Content>
    </Container>
  );
};

export default EditRuleModal;
