import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from 'components/CurrencyInput';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import api from 'services/api';

import { toast } from 'react-toastify';
import { PriceCourts } from 'interfaces/priceCourts';
import { Container, Content } from './styles';

interface PageProps {
  closeModal: () => void;
  idCourt: string;
  priceSelected: PriceCourts;
}

interface LastStepProps {
  price: string;
}

const schema = yup.object().shape({
  price: yup.string().required(),
});

const AddPriceModal: React.FC<PageProps> = ({
  closeModal,
  idCourt,
  priceSelected,
}) => {
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, control, errors } = useForm<LastStepProps>({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    ({ price }: LastStepProps) => {
      // console.log(idCourt, priceSelected);
      setLoading(true);
      const priceFormat = price.replace('R$', '');
      const data = {
        id_court: idCourt,
        id_place: process.env.REACT_APP_ID_PLACE,
        hour: Number(priceSelected.hour),
        price: Number(priceFormat.replace(',', '.')),
        week_day: priceSelected.week_day,
      };

      api.post('/appointments/createPrice', data).then(() => {
        toast.success('Preço criado com sucesso!');
        setLoading(false);
        closeModal();
      });
    },
    [closeModal, idCourt, priceSelected]
  );

  return (
    <Container>
      <ModalHeader title="Novo preço" closeModal={closeModal} />
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CurrencyInput
            control={control}
            label="Preço"
            name="price"
            prefix="R$"
            register={register}
            error={!!errors.price?.message}
          />
          <Button type="submit">{loading ? 'Aguarde...' : 'Salvar'}</Button>
        </form>
      </Content>
    </Container>
  );
};

export default AddPriceModal;
