import TextArea from 'components/TextArea';
import React, { useCallback } from 'react';
import { useMenu } from 'context';
import { toast } from 'react-toastify';
import api from 'services/api';
import { Container, Content } from './styles';

const PrivacyPolicy: React.FC = () => {
  const [defaultValue, setDefaultValue] = React.useState('');
  const [isEditting, setIsEditting] = React.useState(false);
  const [saveDisable, setsaveDisable] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const { setPageTitle } = useMenu();
  React.useEffect(() => {
    setPageTitle('Políticas de Privacidade');
    api
      .get(`/privacyPolicy/find`, {
        params: {
          id_place: 'f13f0061-01f0-476f-9d6c-fe4a1a1f64ca',
        },
      })
      .then((response) => {
        setDefaultValue(response.data.text);
        if (response.data.text.length <= 20) {
          setsaveDisable(true);
        } else {
          setsaveDisable(false);
        }
        setLoading(false);
      });
  }, [setPageTitle]);

  // React.useEffect(() => {}, [defaultValue]);

  const hadleChecklength = useCallback((string: string) => {
    if (string.length <= 20) {
      setsaveDisable(true);
    } else {
      setsaveDisable(false);
    }
    setDefaultValue(string);
  }, []);

  // const handleSaveChanges = useCallback(() => {
  //   api
  //     .get(`/privacyPolicy/find`, {
  //       params: {
  //         id_place: 'f13f0061-01f0-476f-9d6c-fe4a1a1f64ca',
  //       },
  //     })
  //     .then((response) => {
  //       setDefaultValue(response.data.text);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       // eslint-disable-next-line no-console
  //       console.log(err);
  //     });
  // }, []);

  const handleEdit = React.useCallback(() => {
    setIsEditting(true);
  }, []);

  const handleSave = React.useCallback(() => {
    setLoading(true);
    api
      .post(`/privacyPolicy/create`, {
        id_place: 'f13f0061-01f0-476f-9d6c-fe4a1a1f64ca',
        text: defaultValue,
      })
      .then((response) => {
        setDefaultValue(response.data.text);
        if (response.data.text.length <= 20) {
          setsaveDisable(true);
        } else {
          setsaveDisable(false);
        }
        toast.success('Alteração realizada com sucesso!');
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // eslint-disable-next-line no-console
        console.log(err);
      });
    setIsEditting(false);
  }, [defaultValue]);

  return (
    <Container>
      {/* <FullLoader show={loading} /> */}
      <Content>
        <h1>Política de privacidade</h1>
        <TextArea
          isLoadding={loading}
          disabled={!isEditting}
          rows={15}
          cols={100}
          isEditable
          isEditing={isEditting}
          defaultValue={defaultValue}
          handleEdit={handleEdit}
          save={handleSave}
          saveDisable={saveDisable}
          name="policy"
          onChange={(e) => hadleChecklength(e.target.value)}
        />
      </Content>
    </Container>
  );
};

export default PrivacyPolicy;
