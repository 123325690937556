import React, { useEffect, useCallback, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from 'components/CurrencyInput';

import Input from 'components/Input';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import { PackagesProps } from 'interfaces/packages';
import api from 'services/api';

import { toast } from 'react-toastify';
import { Container, Content } from './styles';

interface LastStepProps {
  amount: number;
  price: string;
}

interface PageProps {
  initialData: PackagesProps;
  closeModal: () => void;
}

const schema = yup.object().shape({
  amount: yup.number().required(),
  price: yup.string().required(),
});

const EditPackageModal: React.FC<PageProps> = ({ initialData, closeModal }) => {
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, setValue, control, errors } =
    useForm<LastStepProps>({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    setValue('amount', initialData.amount);
    setValue('price', String(initialData.price).replace('.', ','));
  }, [setValue, initialData]);

  const onSubmit = useCallback(
    ({ amount, price }: LastStepProps) => {
      const data = {
        id: initialData.id,
        amount,
        price,
      };

      api.put('/payers/updatePackage', data).then((response) => {
        toast.success('Pacote atualizado com sucesso!');
        closeModal();
      });
    },
    [initialData, closeModal]
  );

  return (
    <Container>
      <ModalHeader title="Editar pacote" closeModal={closeModal} />
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Quantidade de aulas"
            name="amount"
            register={register}
            error={!!errors.amount?.message}
          />
          <CurrencyInput
            control={control}
            label="Preço"
            name="price"
            prefix="R$ "
            register={register}
            error={!!errors.price?.message}
          />
          <Button type="submit">{loading ? 'Entrando...' : 'Salvar'}</Button>
        </form>
      </Content>
    </Container>
  );
};

export default EditPackageModal;
